import { selectBlogSlug, selectBlogStatus } from "store/blogSinglePage/selector";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "react-lazy-load-image-component/src/effects/blur.css";
import { blogSlug } from "store/blogSinglePage/actions";
import { IMAGE_STARTING_URL } from "utils/constants";
import { DateFormatter } from "utils/helpers";
import { BlogMainParentContainer, Container, Content, LazyImage, Meta, Title } from "containers/blogPage/styled";
import {
  BlogMainTitle,
  ImageForViewedBlogs,
  MostViewedBlog, MostViewedBlogs,
  MostViewedBlogTitle,
  MostViewedOnes, SmallTextBlog
} from "../blog/styled";
import { ROUTENAMES } from "../../routes/routeNames";
import { selectMostViewedBlogs } from "../../store/most-viewed-blogs/selector";
import { blog } from "../../store/blog/actions";
import { mostViewedBlog } from "../../store/most-viewed-blogs/actions";

export const BlogPage = () => {
  const { slug } = useParams();
  const blogSlugData = useSelector(selectBlogSlug);
  const blogSlugDataStatus = useSelector(selectBlogStatus);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const mostViewedBlogData = useSelector(selectMostViewedBlogs);

  useEffect(() => {
    dispatch(mostViewedBlog());
    dispatch(blogSlug({ slug, language: "am" }));
  }, [dispatch, slug]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  return (
    <>
      {blogSlugDataStatus === "loading"
        ? <Container>
          <Title isLoading> Loading... </Title>
        </Container>

        : <BlogMainParentContainer>
          <Container>
            <BlogMainTitle>Գլխավոր > Բլոգ</BlogMainTitle>
            <Title>{blogSlugData.name_blog}</Title>
            <Meta>{t("posted_on")} {blogSlugData?.created_at &&
              <DateFormatter date={blogSlugData.created_at} />}
            </Meta>
            <LazyImage
              effect="blur"
              src={`${IMAGE_STARTING_URL}/${blogSlugData.image}`}
              alt="Blog post image" />
            <Content dangerouslySetInnerHTML={{ __html: blogSlugData.blog_sample_description_am }} />
            <Content dangerouslySetInnerHTML={{ __html: blogSlugData.name_blog }} />
            <Content dangerouslySetInnerHTML={{ __html: blogSlugData.text_one }} />
          </Container>
          <MostViewedBlogs>
            <MostViewedBlogTitle>ՇԱՏ ԸՆԹԵՐՑՎԱԾ</MostViewedBlogTitle>

            <MostViewedOnes>
              {mostViewedBlogData?.map((mostViewed) => (
                <Link to={`${ROUTENAMES.blog}/${mostViewed.slug}`} target={"_blank"} key={`${mostViewed.slug}-${mostViewed.id}`}>
                  <MostViewedBlog>
                    <ImageForViewedBlogs
                      key={mostViewed.id}
                      src={`${IMAGE_STARTING_URL}/${mostViewed?.image}`}
                      alt="Blog Image"
                    />
                    <SmallTextBlog>
                      {mostViewed?.blog_sample_description?.slice(0, 100)}
                    </SmallTextBlog>


                  </MostViewedBlog>
                </Link>
              ))}
            </MostViewedOnes>

          </MostViewedBlogs>
        </BlogMainParentContainer>
      }
    </>
  );
};
