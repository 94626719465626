import phoneIcon from "assets/png/phoneIcon.png";
import logo from "assets/png/logo.png";
import earthIcon from "assets/png/earthIcon.png";
import storeIcon from "assets/png/storeIcon.jpg";
import hamburgerIcon from "assets/png/hamburger-icon.png";
import armeniaFlag from "assets/png/armenianFlag.png";
import russianFlag from "assets/png/russianFlag.png";
import usaFlag from "assets/png/usaFlag.png";
import chinaFlag from "assets/png/chinaFlag.jpg";
import closeIcon from "assets/png/closePng.png";
import userProfilePhoto from "assets/png/userProfileImage.png";
import whiteGrayWallpaper from "assets/png/whiteGrayWallpaper.jpg";
import heartIcon from "assets/png/heartIcon.png";
import closedEye from "assets/svg/eye-closed-password.svg";
import openedEye from "assets/svg/eye-open-password.svg";
import searchIcon from "assets/svg/search-icon.svg";
import nextSlide from "assets/png/next.png";
import prevSlide from "assets/png/prev.png";
import closeIconBlack from "assets/png/close-icon.png";
import carAnimationGif from "assets/gif/canAnimationGif.gif";
import starWallpaper from "assets/png/starWallpaper.jpg";
import starIcon from "assets/svg/starIcon.svg";
import ecoEvoca from "assets/png/eco-evoca.jpg";
import ecoEvocaSmall from "assets/png/eco-evoca-786.jpg";
import ecoIneco from "assets/png/eco-ineco.jpg";
import ecoInecoSmall from "assets/png/eco-ineco-small.jpg";
import facebookIcon from "assets/png/facebookIcon.png";
import share from "assets/svg/share.svg";
import facebook from "assets/svg/icons8-facebook.svg";
import ID6Back from "assets/360Images/id4360/id6back.png";
import ID6Front from "assets/360Images/id4360/id6front.png";
import ID6Middle from "assets/360Images/id4360/id6Middle.png";
import ID6MiddleLeft from "assets/360Images/id4360/id6MiddleLeft.png";
import ID6ToMiddleLeft from "assets/360Images/id4360/id6MiddleLeft.png";
import ID6ToLeft from "assets/360Images/id4360/id6ToLeft.png";
import ID6ToLeftEnd from "assets/360Images/id4360/id6ToLeftEnd.png";
import ID6ToLeftEndBack from "assets/360Images/id4360/id6ToLeftEndBack.png";
import ID6ToLeftMiddle from "assets/360Images/id4360/id6ToLeftMiddle.png";
import ID6ToRight from "assets/360Images/id4360/id6ToRight.png";
import ID6ToRightEnd from "assets/360Images/id4360/id6ToRightEnd.png";
import ID6ToRightEndFront from "assets/360Images/id4360/id6ToRightEndFront.png";
import ID6ToRightMiddle from "assets/360Images/id4360/id6ToRightMiddle.png";
import ArmenianLeasingBackground from "assets/png/eco-armenianlizing.jpg";
import ArmenianLeasingSmall from "assets/png/armenian-leasing-small.jpg";
import goBackButton from "assets/png/goBack.jpg";
import artsakhFlag from "assets/png/artsakhFlag.png";
import ID4Front from "assets/360Images/id6360/ID6ElectroFront.png";
import ID4FrontMidRight from "assets/360Images/id6360/ID6FrontMidRight.png";
import ID4FrontRight from "assets/360Images/id6360/ID6FrontRight.png";
import ID4Mid from "assets/360Images/id6360/ID6Mid.png";
import ID4MidToRight from "assets/360Images/id6360/ID6MidToRight.png";
import ID4BackToRight from "assets/360Images/id6360/ID6BackToRight.png";
import ID4Back from "assets/360Images/id6360/ID6ElectroBack.png";
import ID4RightToFront from "assets/360Images/id6360/ID6RightToFront.png";
import ID4BackToMid from "assets/360Images/id6360/ID6BackToMid.png";
import ID4RightToMid from "assets/360Images/id6360/ID6RightMid.png";
import ID4ToFront from "assets/360Images/id6360/ID6ToFront.png";
import ID4ToFrontLeft from "assets/360Images/id6360/ID6ToFrontLeft.png";
import ID6backforAnim from "assets/360Images/id6360/id6backforAnim.jpg";
import ID6FrontforAnim from "assets/360Images/id6360/id6FrontForAnim.jpg";
import hondaENP1Slider from "assets/carModelsSlider/hondaEnp1Slider.png";
import hondaENS1Slider from "assets/carModelsSlider/hondaEns1Slider.png";
import id4Slider from "assets/carModelsSlider/id4Slider.png";
import id6slider from "assets/carModelsSlider/id6Slider.png";
import mercedesEQC from "assets/carModelsSlider/MercedesEQC.png";
import toyotaBZ4Slider from "assets/carModelsSlider/toyotaBZ4Slider.png";
import MitsubishiAirtek from "assets/carModelsSlider/mitsubishiairtek.jpeg";
import HondaFront from "assets/360Images/hondaENS1/hondaFront.png";
import HondaEco from "assets/360Images/hondaENS1/hondaEco.jpg";
import HondaEcoFront from "assets/360Images/hondaENS1/hondaEcoFront.jpg";
import HondaToRight from "assets/360Images/hondaENS1/hondaToRight.png";
import HondaToRightMid from "assets/360Images/hondaENS1/HondaToRightMid.png";
import HondaMid from "assets/360Images/hondaENS1/HondaMid.png";
import HondaToRightBack from "assets/360Images/hondaENS1/HondaToRightBack.png";
import HondaToRightEnd from "assets/360Images/hondaENS1/HondaToRightEnd.png";
import HondaBack from "assets/360Images/hondaENS1/hondaBack.png";
import HondaToLeftEnd from "assets/360Images/hondaENS1/HondaToLeftEnd.png";
import HondaToLeftBack from "assets/360Images/hondaENS1/HondaToLeftBack.png";
import HondaLeftMid from "assets/360Images/hondaENS1/HondaLeftMid.png";
import HondaToLeftMid from "assets/360Images/hondaENS1/HondaToLeftMid.png";
import HondaToLeft from "assets/360Images/hondaENS1/HondaToLeft.png";
import HondaFrontBackground from "assets/360Images/hondaENS1/HondaFrontBackground.png";
import HondaBackBackground from "assets/360Images/hondaENS1/HondaBackBackground.png";
import phoneEcoMotors from "assets/ecoMotorsDevices/phoneEcoMotors.png";
import macBookEcoMotors from "assets/ecoMotorsDevices/macBookEcoMotors.png";
import ImacEcoMotors from "assets/ecoMotorsDevices/ImacEcoMotors.png";
import sedanIcon from "assets/png/sedan.png";
import crossoverIcon from "assets/png/crossover.png";
import truckIcon from "assets/png/truck.png";
import minivanIcon from "assets/png/minvan.png";
import hatchbackIcon from "assets/png/hatchback.png";
import miniIcon from "assets/png/mini.png";
import eqsFront from "assets/360Images/eqs/eqsfront.png";
import eqsFrontToRight from "assets/360Images/eqs/eqsfronttoright.png";
import eqsFrontToRight2 from "assets/360Images/eqs/eqsfronttoright2.png";
import eqsRightToMid from "assets/360Images/eqs/eqsrightomid.png";
import eqsMidRight from "assets/360Images/eqs/eqsmidright.png";
import eqsMidToRightBack from "assets/360Images/eqs/eqsmidtorightback.png";
import eqsMidToRightBack2 from "assets/360Images/eqs/eqsmidtorightback2.png";
import eqsRightToBack from "assets/360Images/eqs/eqsrightomid.png";
import eqsBack from "assets/360Images/eqs/eqsback.png";
import eqsBackToRight from "assets/360Images/eqs/eqsbacktoright.png";
import eqsMidToLeftBack2 from "assets/360Images/eqs/eqsmidtoleftback2.png";
import eqsMidToLeftBack from "assets/360Images/eqs/eqsmidtoleftback.png";
import eqsMidLeft from "assets/360Images/eqs/eqsmidleft.png";
import eqsMidToLeft from "assets/360Images/eqs/eqsmidtoleft.png";
import eqsLeftToMid from "assets/360Images/eqs/eqslefttomid.png";
import eqsMidToLeft2 from "assets/360Images/eqs/eqsmidtoleft2.png";
import bz4xBack from "assets/autoImages/toyotaBZ4Back.jpg";
import bz4xFront from "assets/autoImages/toyotaBZ4.jpg";
import hondaens1slider from "assets/autoImages/hondaens1slider.jpg";
import mercedesEQSSlider from "assets/autoImages/mercedeseqsslider.jpg";
import fordSlider from "assets/autoImages/fordslider.jpg";
import lexusSlider from "assets/autoImages/lexusslider.jpg";
import audiSlider from "assets/autoImages/audiSlider.jpg";
import dongfengSlider from "assets/autoImages/dongfentSlider.jpg";
import ID4electro from "assets/autoImages/ID4Wallpaper.png";
import ID6electro from "assets/autoImages/ID6Wallpaper.jpg";
import ID6Wallpaper from "assets/autoImages/ID6Wallpaper.jpg";
import electroCarWallpaper from "assets/autoImages/electricCarWallpaper.jpg";
import carCharger from "assets/autoImages/carCharger.jpg";
import electricCarCharging from "assets/autoImages/electroCarCharging.jpg";
import mediumSUVICon from "assets/png/mediumSUV.png";
import mitsubishiFront from "assets/360Images/mitsubishi/Screenshot 2024-01-11 231052.png";
import mitsubishi2 from "assets/360Images/mitsubishi/Screenshot 2024-01-11 231108.png";
import mitsubishi3 from "assets/360Images/mitsubishi/Screenshot 2024-01-11 231122.png";
import mitsubishi4 from "assets/360Images/mitsubishi/Screenshot 2024-01-11 231143.png";
import mitsubishi5 from "assets/360Images/mitsubishi/Screenshot 2024-01-11 231157.png";
import mitsubishi6 from "assets/360Images/mitsubishi/Screenshot 2024-01-11 231227.png";
import mitsubishi7 from "assets/360Images/mitsubishi/Screenshot 2024-01-11 231238.png";
import mitsubishi8 from "assets/360Images/mitsubishi/Screenshot 2024-01-11 231247.png";
import mitsubishi9 from "assets/360Images/mitsubishi/Screenshot 2024-01-11 231257.png";
import mitsubishi10 from "assets/360Images/mitsubishi/Screenshot 2024-01-11 231306.png";
import mitsubishi11 from "assets/360Images/mitsubishi/Screenshot 2024-01-11 231317.png";
import spareParts from "assets/png/spareParts.png";
import advertisement from "assets/png/advertisement.png";
import advertisementSmall from "assets/png/advertisementSmall.png";
import MopedsBackground from "assets/mopedImages/mopedMainBackground.jpg";
import ruichiLogo from "assets/png/RUiCHi_logo.png";
import donfengLogo from "assets/png/dongfent_logo.png";
import zeekrAdv from "assets/newImages/zeekr-advertisement.gif";
import newHamburgerIcon from "assets/newImages/hamburgerIcon.png";
import newEcoMotorsLogo from "assets/newImages/logo.png";
import ArmLogo from "assets/newImages/armeniaLogo.png";
import russianLogo from "assets/newImages/russianLogo.png";
import usaLogo from "assets/newImages/usaLogo.png";
import EcoMotorsAdvertisement from "assets/newImages/ecoMotorsAdvertisement.png";
import testAdv from "assets/newImages/testAdvertisement.png";
import officialPartner from "assets/newImages/officialPartner.png";
import ourPartnersBig from "assets/newImages/ourPartnersBig.png";
import ajapnyak from "assets/newImages/ajapnyak.png";
import davitBek from "assets/newImages/davitbek.png";
import sevan from "assets/newImages/sevan.png";
import ejmiatsin from "assets/newImages/ejmiatsin.png";
import melkumov from "assets/newImages/melkumov.png";
import importantAdvertisement from "assets/newImages/importantAdvertisement.png";
import motorcycleAdvertisement from "assets/newImages/motorcycleAdvertisement.png";
import dongfengGif from "assets/newImages/dongfeng.gif";
import ruichiGif from "assets/newImages/mob-ruichi.gif";
import ruichiGif2 from "assets/newImages/mob-ruichi2.gif";
import left1 from "assets/newImages/left1.png";
import left2 from "assets/newImages/left2.png";
import right1 from "assets/newImages/right1.png";
import right2 from "assets/newImages/right2.png";
import marketPc from "assets/newImages/market-pc.jpg";
import gifMiniScreen from "assets/newImages/55062dee49013d6bb650ace210c325fd.gif";

export const Images = {
  gifMiniScreen,
  left1,
  left2,
  right1,
  right2,
  ajapnyak,
  davitBek,
  sevan,
  ejmiatsin,
  melkumov,
  bz4xBack,
  bz4xFront,
  ID6Back,
  ID6Front,
  ID6ToMiddleLeft,
  ID6ToLeft,
  ID6ToLeftMiddle,
  ID6ToRight,
  ID6ToRightEnd,
  ID6ToRightEndFront,
  ID6ToRightMiddle,
  ID6ToLeftEndBack,
  ID6ToLeftEnd,
  ID6MiddleLeft,
  ID6Middle,
  phoneIcon,
  logo,
  earthIcon,
  storeIcon,
  hamburgerIcon,
  ID4electro,
  ID6electro,
  ID6Wallpaper,
  electroCarWallpaper,
  carCharger,
  electricCarCharging,
  armeniaFlag,
  russianFlag,
  usaFlag,
  chinaFlag,
  closeIcon,
  userProfilePhoto,
  whiteGrayWallpaper,
  heartIcon,
  closedEye,
  openedEye,
  searchIcon,
  nextSlide,
  prevSlide,
  closeIconBlack,
  carAnimationGif,
  starWallpaper,
  ecoEvoca,
  ecoEvocaSmall,
  ecoIneco,
  ecoInecoSmall,
  share,
  facebookIcon,
  facebook,
  starIcon,
  ArmenianLeasingBackground,
  ArmenianLeasingSmall,
  goBackButton,
  artsakhFlag,
  ID4Front,
  ID4FrontMidRight,
  ID4FrontRight,
  ID4Mid,
  ID4MidToRight,
  ID4BackToRight,
  ID4Back,
  ID4RightToFront,
  ID4BackToMid,
  ID4RightToMid,
  ID4ToFront,
  ID4ToFrontLeft,
  ID6backforAnim,
  ID6FrontforAnim,
  hondaENP1Slider,
  hondaENS1Slider,
  id4Slider,
  id6slider,
  mercedesEQC,
  toyotaBZ4Slider,
  MitsubishiAirtek,
  HondaFront,
  HondaToRight,
  HondaToRightMid,
  HondaMid,
  HondaToRightBack,
  HondaToRightEnd,
  HondaBack,
  HondaToLeftEnd,
  HondaToLeftBack,
  HondaLeftMid,
  HondaToLeftMid,
  HondaToLeft,
  HondaFrontBackground,
  HondaBackBackground,
  phoneEcoMotors,
  macBookEcoMotors,
  ImacEcoMotors,
  sedanIcon,
  truckIcon,
  crossoverIcon,
  minivanIcon,
  hatchbackIcon,
  miniIcon,
  HondaEco,
  HondaEcoFront,
  eqsFront,
  eqsFrontToRight,
  eqsFrontToRight2,
  eqsRightToMid,
  eqsMidRight,
  eqsMidToRightBack,
  eqsMidToRightBack2,
  eqsRightToBack,
  eqsBack,
  eqsBackToRight,
  eqsMidToLeftBack2,
  eqsMidToLeftBack,
  eqsMidLeft,
  eqsMidToLeft,
  eqsMidToLeft2,
  eqsLeftToMid,
  hondaens1slider,
  mercedesEQSSlider,
  fordSlider,
  lexusSlider,
  audiSlider,
  dongfengSlider,
  mediumSUVICon,
  mitsubishiFront,
  mitsubishi2,
  mitsubishi3,
  mitsubishi4,
  mitsubishi5,
  mitsubishi6,
  mitsubishi7,
  mitsubishi8,
  mitsubishi9,
  mitsubishi10,
  mitsubishi11,
  spareParts,
  advertisement,
  advertisementSmall,
  MopedsBackground,
  ruichiLogo,
  donfengLogo,
  zeekrAdv,
  newHamburgerIcon,
  newEcoMotorsLogo,
  ArmLogo,
  russianLogo,
  usaLogo,
  EcoMotorsAdvertisement,
  testAdv,
  officialPartner,
  ourPartnersBig,
  importantAdvertisement,
  motorcycleAdvertisement,
  dongfengGif,
  ruichiGif,
  ruichiGif2,
  marketPc
};
