import styled, { keyframes } from "styled-components";
import { COLORS } from "utils/colors";
import { Link } from "react-router-dom";

export const BlogContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 20px;
`;

export const BlogMainTitle = styled.div`
    width: 100%;
    color: #03202280;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const BlogFirstImage = styled.img`
    width: 100%;
`;

export const slideFromLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
`;
export const BlogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  width: 100%;
  position: relative;
  overflow: hidden;

  a {
    text-decoration: none;
  }
`;

export const MostViewedBlogs = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: rgba(217, 217, 217, 0.4);
    gap: 20px;
    padding-top: 10px;
    overflow-y: hidden;
    
    @media only screen and (max-width: 1280px) {
        background: unset;
    }

    a {
        text-decoration: none;
    }
`;

export const MostViewedBlogTitle = styled.span`
    font-family: sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0;
`;

export const MostViewedOnes = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    @media only screen and (max-width: 1280px) {
        width: fit-content;
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        gap: 10px;
        padding-inline: 10px;
    }
`;

export const MostViewedBlog = styled.div`
    width: 100%;
    height: 78px;
    background: rgba(217, 217, 217, 1);
    display: flex;
    gap: 10px;
    overflow: hidden;
    
    @media only screen and (max-width: 1280px) {
        width: 300px;
        border-radius: 10px;
    }
`;

export const ImageForViewedBlogs = styled.img`
    width: 30%;
    border-radius: 14px;
    object-fit: cover;
`;

export const TextAndInfoAboutViewedBlog = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SmallTextBlog = styled.span`
    font-weight: 600;
    font-size: 12px;
    color: rgba(0, 137, 0, 1);
    text-align: initial;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const MainBlogParent = styled.div`
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 20px;
    width: 100%;
    overflow: hidden;

    @media only screen and (max-width: 1280px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;

        & > :nth-child(1) {
            order: 2;
        }

        & > :nth-child(2) {
            order: 1;
        }
    }
`;


export const BlogCard = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${COLORS.white};
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    transition: 0.5s;
    position: relative;
    
    .lazy-load-image-background {
        width: 290px;
        height: 232px;
        flex-shrink: 0;
    }

    .lazy-load-image-background img {
        width: 100%;
        height: 100%;
        border-radius: 15px;
    }
    
    @media only screen and (max-width: 768px) {
        flex-direction: column;

        .lazy-load-image-background {
            width: 100%;
            height: 232px;
            flex-shrink: 0;
        }
    }

    @media only screen and (max-width: 428px) {
        width: ${({ welcome }) => welcome ? "280px" : "100%"};
        margin: 0;
    }

    img {
        max-width: 100%;
        height: 200px;
        object-fit: contain;
    }
`;

export const BlogDetails = styled.div`
  width: 100%;
  position: relative;
  color: ${COLORS.black};
  padding: 20px;
  display: flex;
  flex-direction: column;
  padding-top: 40px;  
  background: #D9D9D940;  
`;

export const BlogTitle = styled.h1`
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #008900;
    width: 100%; 
    font-size: 18px;
    font-weight: 700;
`;

export const BlogAuthor = styled.p`
  font-size: 14px;
  color: ${COLORS.black};
`;

export const BlogDate = styled.p`
  font-size: 14px;
  position: absolute;  
  color: #00000080;
  font-weight: 500;
  top: 0;  
`;

export const BlogDescription = styled.p`
  font-size: 16px;
  text-align: initial;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BlogType = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 34px;
    background: #0000001A;
    color: #000000;
    font-family: Montserrat arm;
    border-radius: 6px;
`;

export const BlogTypeWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 8px;
`;

export const FaEyeAndCount = styled.div`
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
`;


export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media only screen and (max-width: 768px) {
    margin-top: 30px;
  }
`;

export const LinkForBlock = styled(Link)`
  border-radius: 50%;
  background-color: royalblue;
`;

export const slideIn = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  50% {
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0;
  }
`;


export const BlogAnimatedTitle = styled.h1`
  font-size: 5vw;
  font-weight: bold;
  letter-spacing: 0.2rem;
  text-align: center;
  color: ${COLORS.main_color};
`;
