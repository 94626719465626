import {
  EcoMotorsAdvertisement,
  EcoMotorsLogo,
  EcoMotorsNumbersAndSocialLinks,
  EmailText,
  HamburgerIcon,
  HamburgerIconWithLogo,
  Header,
  HeaderAdvertisement,
  HeaderSearch,
  HeaderSearchInput,
  HeaderSearchInputMini,
  HeaderSingleUnit,
  MainHeaderItems,
  MainWrapperOfHeader,
  MenuContainer,
  MenuContainerSide,
  NumbersAndEmail,
  NumberText,
  SearchIcon,
  SearchWithLanguages,
  SocialMediaLinks,
  StyledHeaderWrapper
} from "./styled";
import { Images } from "../../assets";
import { FaSearch } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTENAMES } from "../../routes/routeNames";
import { useEffect, useState } from "react";

export const NewHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const openModal = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (search != null) {
        localStorage.setItem("searchWithArticulate", search);
        navigate(`${ROUTENAMES.cars}/${search}`);
      }
    }
  };

  useEffect(() => {
    setIsOpen(false)
  }, [location]);

  const handleNavigation = () => {
    if (search != null) {
      localStorage.setItem("searchWithArticulate", search);
      navigate(`${ROUTENAMES.cars}/${search}`);
    }
  };

  return (
    <StyledHeaderWrapper>
      <HeaderAdvertisement />
      <MainHeaderItems>
        {isOpen ? <MenuContainer>
          <MenuContainerSide>
            <Header>ՄԵՆՅՈՒ</Header>
            <HeaderSingleUnit onClick={() => navigate(ROUTENAMES.available_cars)}>Առկա Մեքենաներ</HeaderSingleUnit>
            <HeaderSingleUnit onClick={() => navigate(ROUTENAMES.cars)}>Մեքենաներ</HeaderSingleUnit>
            {/*<HeaderSingleUnit>Ավտոպահեստամասեր</HeaderSingleUnit>*/}
            <HeaderSingleUnit onClick={() => navigate(ROUTENAMES.cars)}>Մոտոցիկլետներ</HeaderSingleUnit>
            <HeaderSingleUnit onClick={() => navigate(ROUTENAMES.active_motorcycles)}>Առկա Մոտոցիկլներներ</HeaderSingleUnit>
            {/*<HeaderSingleUnit>Տեխզննում և Տեխսպասարկում</HeaderSingleUnit>*/}
            <HeaderSingleUnit onClick={() => navigate(ROUTENAMES.about)}>Մեր Մասին</HeaderSingleUnit>
            <HeaderSingleUnit onClick={() => navigate(ROUTENAMES.our_addresses)}>Մեր Սրահները</HeaderSingleUnit>
            <HeaderSingleUnit onClick={() => navigate(ROUTENAMES.blog)}>Բլոգ</HeaderSingleUnit>
          </MenuContainerSide>
          <MenuContainerSide>
            <EcoMotorsAdvertisement src={Images.EcoMotorsAdvertisement}/>
            <EcoMotorsNumbersAndSocialLinks>
              <NumbersAndEmail>
                <NumberText>+374-95-94-94-66</NumberText>
                <NumberText>+374-96-94-94-66</NumberText>
                <EmailText>info@ecomotors.am</EmailText>
              </NumbersAndEmail>
              <SocialMediaLinks>
                <FaFacebookF className="fa__icon"/>
                <FaLinkedinIn className="fa__icon"/>
                <IoLogoYoutube className="fa__icon"/>
                <FaInstagram className="fa__icon"/>
                <FaTiktok className="fa__icon"/>
              </SocialMediaLinks>
            </EcoMotorsNumbersAndSocialLinks>
          </MenuContainerSide>
        </MenuContainer> : null}
        <MainWrapperOfHeader>
          <HamburgerIconWithLogo>
            <HamburgerIcon src={Images.newHamburgerIcon} onClick={openModal} alt="loading an image"/>
            <EcoMotorsLogo onClick={() => navigate(ROUTENAMES.welcome)} src={Images.newEcoMotorsLogo} alt="loading an image"/>
          </HamburgerIconWithLogo>
          <SearchWithLanguages>
            <HeaderSearchInput>
              <HeaderSearch
                placeholder="Փնտրել..."
                onChange={e => setSearch(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <SearchIcon onClick={handleNavigation}>
                <FaSearch className="search__icon"/>
              </SearchIcon>
            </HeaderSearchInput>
            {/*<LanguagesDropDown>*/}
            {/*  <LanguageIcon />*/}
            {/*  ՀԱՅ*/}
            {/*  <FaCaretDown />*/}
            {/*</LanguagesDropDown>*/}
          </SearchWithLanguages>
          {/*<HeaderSearchInputMini>*/}
          {/*  <HeaderSearch placeholder="Փնտրել..."*/}
          {/*                onChange={e => setSearch(e.target.value)}*/}
          {/*                onKeyDown={handleKeyDown}/>*/}
          {/*  <SearchIcon onClick={handleNavigation}>*/}
          {/*    <FaSearch className="search__icon"/>*/}
          {/*  </SearchIcon>*/}
          {/*</HeaderSearchInputMini>*/}
        </MainWrapperOfHeader>
      </MainHeaderItems>
    </StyledHeaderWrapper>
  )
}