import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { selectAvailableCars } from "store/availableCars/selector";
import { availableCars } from "store/availableCars/actions";
import { useTranslation } from "react-i18next";
import { Salon } from "store/salonLocalizationsIds/actions";
import { selectSalons } from "store/salonLocalizationsIds/selector";
import { onlySalonData } from "store/onlySalon/actions";
import { selectOnlySalonData } from "store/onlySalon/selector";
import {
  ActiveCarsAndWithOrder, ActiveCarsText,
  AvailableCarsLocations,
  AvailableWrapper,
  CheckboxForSalon,
  Content,
  ContentTitle, ContentTitleWithAllText, ContentTitleWithTypes,
  EverySalonWrapper,
  ImportantAdvertisementImage,
  SalonText, WithOrderText,
} from "containers/availableCars/styled";
import { AvailableCarsForSingle } from "../../components/availableCarsForSingle";
import { Images } from "../../assets";
import { useNavigate } from "react-router-dom";
import { ROUTENAMES } from "../../routes/routeNames";


export const AvailableCars = () => {
  const [number, setNumber] = useState(0);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectActiveCarsData = useSelector(selectAvailableCars);
  const selectNumberCars = useSelector(selectOnlySalonData);
  const selectActiveSalons = useSelector(selectSalons);
  const navigate = useNavigate();

  const filteredCars = number > 0 ? selectNumberCars : selectActiveCarsData;

  useEffect(() => {
    dispatch(availableCars());
    dispatch(Salon());
  }, [dispatch]);

  useEffect(() => {
    if(number >= 1) {
      dispatch(onlySalonData(number))
    }
  }, [dispatch, number])

  return (
    <AvailableWrapper>
      <Content>
        <AvailableCarsLocations>
            <EverySalonWrapper key={'id-1'}>
              <CheckboxForSalon
                type='checkbox'
                onChange={() => setNumber(0)}
                checked={number === 0}
              />
              <SalonText>{t('all_cars')}</SalonText>
            </EverySalonWrapper>
            {selectActiveSalons?.map((element, index) =>
              element.id === 6 || element.id === 5 ? null : (
                <EverySalonWrapper key={index}>
                  <CheckboxForSalon
                    type='checkbox'
                    onChange={() => setNumber(element.id)}
                    checked={number === element.id}
                  />
                  <SalonText>{element.salon_localization_text}</SalonText>
                </EverySalonWrapper>
              )
            )}
        </AvailableCarsLocations>
        <ContentTitleWithTypes>
          <ContentTitleWithAllText>
            <ContentTitle>{t("car_available")}</ContentTitle>
            {t("all_cars")}
          </ContentTitleWithAllText>
          <ActiveCarsAndWithOrder>
            <ActiveCarsText>{t("available")}</ActiveCarsText>
            <WithOrderText onClick={() => navigate(ROUTENAMES.cars)}>{t("with_order")}</WithOrderText>
          </ActiveCarsAndWithOrder>
        </ContentTitleWithTypes>

        <AvailableCarsForSingle filteredCars={filteredCars} />
      </Content>
      <ImportantAdvertisementImage src={Images.importantAdvertisement} alt='advertisement' />
    </AvailableWrapper>
  );
};