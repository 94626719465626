import { CarType } from "../../components/welcome/carType";
import { SearchCarsBlock } from "../../components/welcome/SearchCarsBlock";
import React, { useRef, useState } from "react";
import { WelcomeNewAdvertisementWithEcoMotors } from "../../components/welcome-new-advertisement-with-eco-motors";
import { TopAnnouncements } from "../../components/welcome/topAnnouncements";
import { CarsSlider } from "../../components/welcome/carsSlider";
import { Images } from "../../assets";
import { WelcomePageOfficialPartner } from "./styled";
import { OurPartners } from "../../components/ourPartners";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import {
  FilterParent,
  FilterWithButtonAndIcon,
  HeaderSearch,
  HeaderSearchInputMini,
  SearchIcon
} from "../../components/newHeader/styled";
import { FaSearch } from "react-icons/fa";
import { ROUTENAMES } from "../../routes/routeNames";
import { useNavigate } from "react-router-dom";


export const Welcome = () => {
  const [isOpen, setIsOpen] = useState(false);
  const myRef = useRef(null);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (search != null) {
        localStorage.setItem("searchWithArticulate", search);
        navigate(`${ROUTENAMES.cars}/${search}`);
      }
    }
  };

  const handleNavigation = () => {
    if (search != null) {
      localStorage.setItem("searchWithArticulate", search);
      navigate(`${ROUTENAMES.cars}/${search}`);
    }
  };

  return (
    <>
      <CarType />
      <HeaderSearchInputMini>
        <HeaderSearch placeholder="Փնտրել..."
                      onChange={e => setSearch(e.target.value)}
                      onKeyDown={handleKeyDown}/>
        <SearchIcon onClick={handleNavigation}>
          <FaSearch className="search__icon"/>
        </SearchIcon>
      </HeaderSearchInputMini>

      <FilterParent>
        <FilterWithButtonAndIcon onClick={() => setIsOpen(!isOpen)}>
          <HiOutlineAdjustmentsHorizontal />
          Ֆիլտր
        </FilterWithButtonAndIcon>
      </FilterParent>

      {isOpen ? <SearchCarsBlock /> : window.innerWidth > 768 ? <SearchCarsBlock /> : null}
      <WelcomeNewAdvertisementWithEcoMotors />
      <TopAnnouncements myRef={myRef} />
      <CarsSlider />
      <WelcomePageOfficialPartner src={Images.officialPartner} />
    </>
  );
};

