import { createAsyncThunk } from "@reduxjs/toolkit";
import { blogService } from "store/blog/service";

export const blog = createAsyncThunk(
  "blog/fetch",
  async(data, { rejectWithValue }) => {
    try {
      const response = await blogService.blog(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
