import styled from "styled-components";
import { DefaultFont } from "utils/defaultFont";
import { COLORS } from "utils/colors";

export const OurAddressesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 10px;
  padding-bottom: 30px;
  box-sizing: border-box;
  width: 100%;
`;

export const OurAddressesTitle = styled.span`
  width: 100%;
  height: 70px;
  font-size: 40px;
  font-weight: bold;
  ${DefaultFont};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.main_color};

  @media only screen and (max-width: 768px) {
    font-size: 26px;
  }
`;

export const AddressesBlock = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
    padding-bottom: 10px;
    padding-inline: 10px;

    @media only screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const EveryAddressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  border-radius: 4px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const EveryAddressTitle = styled.span`
  box-sizing: border-box;
  width: 100%;
  font-size: 10px;
  color: #000000;
  ${DefaultFont};
  font-weight: bold;

  @media only screen and (max-width: 768px) {
    font-size: 8px;
  }
`;

export const AddressImage = styled.img`
    height: 186px;
    object-fit: contain;
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 350px;
  border: 0;
`;
