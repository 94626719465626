import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { DateFormatter } from "utils/helpers";
import { selectBlog, selectBlogStatus } from "store/blog/selector";
import { blog } from "store/blog/actions";
import { mostViewedBlog } from "../../store/most-viewed-blogs/actions";
import { IMAGE_STARTING_URL } from "utils/constants";
import { ROUTENAMES } from "routes/routeNames";
import { FaEye } from "react-icons/fa";
import { Images } from "assets";
import {
  BlogCard,
  BlogContainer,
  BlogDate,
  BlogDescription,
  BlogDetails,
  BlogFirstImage,
  BlogMainTitle,
  BlogTitle,
  BlogType, BlogTypeWrapper,
  BlogWrapper, FaEyeAndCount,
  ImageForViewedBlogs,
  MainBlogParent,
  MostViewedBlog,
  MostViewedBlogs,
  MostViewedBlogTitle, MostViewedOnes,
  SmallTextBlog
} from "containers/blog/styled";
import { selectMostViewedBlogs } from "../../store/most-viewed-blogs/selector";

export const Blog = () => {
  const dispatch = useDispatch();
  const blogData = useSelector(selectBlog);
  const mostViewedBlogData = useSelector(selectMostViewedBlogs);
  const [count, setCount] = useState(6);

  useEffect(() => {
    dispatch(blog({ language: "am", count }));
    dispatch(mostViewedBlog());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  return (
    <BlogContainer>
      <BlogMainTitle>Գլխավոր > Բլոգ</BlogMainTitle>
      <BlogFirstImage src={Images.marketPc} alt="noimg" />

      <MainBlogParent>
        <BlogWrapper>
          {blogData?.map((element) => (
            <Link to={`${ROUTENAMES.blog}/${element.slug}`} target={"_blank"} key={`${element.slug}-${element.id}`}>
              <BlogCard className="blog-card" key={element.id}>
                <LazyLoadImage
                  key={element.id}
                  effect="blur"
                  src={`${IMAGE_STARTING_URL}/${element?.image}`}
                  alt="Blog Image"
                />

                <BlogDetails>
                  <BlogTitle>{element?.name_blog}</BlogTitle>
                  {/*<BlogAuthor>{element?.blog_writer_name}</BlogAuthor>*/}
                  <BlogDate>
                    {element?.created_at && (
                      <DateFormatter date={element.created_at} />
                    )}
                  </BlogDate>
                  <BlogDescription>
                    {element?.blog_sample_description}
                  </BlogDescription>
                  <BlogTypeWrapper>
                    <BlogType>Ավտո լուրեր</BlogType>
                    <FaEyeAndCount>
                      <FaEye />
                      {element?.views}
                    </FaEyeAndCount>

                  </BlogTypeWrapper>
                </BlogDetails>
              </BlogCard>
            </Link>
          ))}
        </BlogWrapper>

        <MostViewedBlogs>
          <MostViewedBlogTitle>ՇԱՏ ԸՆԹԵՐՑՎԱԾ</MostViewedBlogTitle>

          <MostViewedOnes>
            {mostViewedBlogData?.map((mostViewed) => (
              <Link to={`${ROUTENAMES.blog}/${mostViewed.slug}`} target={"_blank"} key={`${mostViewed.slug}-${mostViewed.id}`}>
                <MostViewedBlog>
                  <ImageForViewedBlogs
                    key={mostViewed.id}
                    src={`${IMAGE_STARTING_URL}/${mostViewed?.image}`}
                    alt="Blog Image"
                  />
                  <SmallTextBlog>
                    {mostViewed?.blog_sample_description?.slice(0, 100)}
                  </SmallTextBlog>


                </MostViewedBlog>
              </Link>
            ))}
          </MostViewedOnes>

        </MostViewedBlogs>


      </MainBlogParent>
    </BlogContainer>
  );
};
