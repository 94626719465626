import { createSlice } from "@reduxjs/toolkit";
import { mostViewedBlog } from "store/most-viewed-blogs/actions";
import { REDUX_STATES } from "utils/constants";

const initialState = {
  blogStatus: null,
  data: [],
  eightBlogsData: [],
  error: null,
  errors: "",
  errorMessage: "",
  is_loading: false,
};

export const mostViewedBlogsData = createSlice({
  name: "mostViewedBlog/fetch",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
        case "blogStatus":
          state.blogStatus = null;
          break;
        default:
          break;
      }
    },
  },

  extraReducers: {
    [mostViewedBlog.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.is_loading = false;
      state.blogStatus = REDUX_STATES.PENDING;
    },
    [mostViewedBlog.fulfilled]: (state, action) => {
      state.data = action.payload.blogMostViews;
      state.is_loading = false;
      state.blogStatus = REDUX_STATES.SUCCEEDED;
    },
    [mostViewedBlog.rejected]: (state, { payload }) => {
      state.is_loading = false;
      state.blogStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = mostViewedBlogsData.actions;

export default mostViewedBlogsData.reducer;
