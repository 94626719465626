import { createAsyncThunk } from "@reduxjs/toolkit";
import { mostViewedBlogService } from "store/most-viewed-blogs/service";

export const mostViewedBlog = createAsyncThunk(
  "mostViewedBlog/fetch",
  async(_, { rejectWithValue }) => {
    try {
      const response = await mostViewedBlogService.mostViewedBlog();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
