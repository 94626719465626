import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/slice";
import contactReducer from "./contact/slice";
import blogReducer from "./blog/slice";
import blogSlugReducer from "./blogSinglePage/slice";
import mostViewedBlogsDataReducer from "./most-viewed-blogs/slice";
import carsReducer from "./cars/slice";
import carSlugReducer from "./carSinglePage/slice";
import carModelReducer from "./carModels/slice";
import carNamesReducer from "./carNames/slice";
import websiteDataReducer from "./websiteMainData/slice";
import aboutUsDataReducer from "./aboutUs/slice";
import topCarReducer from "./topAnnouncments/slice";
import favouriteCarReducer from "./favourite/slice";
import profileDataReducer from "./profile/slice";
import userLikedCarsData from "./likedCars/slice";
import ChangeUserProfileDataReducer from "./changeProfileInfo/slice";
import currencyExchangeReducer from "./currencyExchange/slice";
import countryDataReducer from "./country/slice";
import carFilterCountryReducer from "./carFilterCountry/slice";
import urgentSaleReducer from "./urgentSale/slice";
import ecoMotorsServiceReducer from "./services/slice";
import serviceSlugReducer from "./serviceSinglePage/slice";
import carTypeDataReducer from "./carTypes/slice";
import CarWithTypeSlugDataReducer from "./carWithTypeSlug/slice";
import creditWithBankDataReducer from "./creditWithBank/slice";
import creditWithCardPostReducer from "./creditWithCardPost/slice";
import advertisementReducer from "./advertisement/slice";
import writeReviewReducer from "./writeReview/slice";
import seeReviewReducer from "./seeReview/slice";
import sparePartsInfoDataReducer from "./sparePartsInfo/slice";
import sparePartsCategoryReducer from "./sparePartsCategory/slice";
import sparePartWithModelIdReducer from "./sparePartsProductWithModelId/slice";
import requestSparePartReducer from "./requestSparePart/slice";
import ourTeamReducer from "./ourTeam/slice";
import AvailableCarsSliceReducer from "./availableCars/slice";
import CarCompareDataReducer from "./carsCompare/slice";
import carCompareSlugReducer from "./carSinglePageCompare/slice";
import AvailableSparePartsCarsReducer from "./availableCarSparePart/slice";
import SalonDataReducer from "./salonLocalizationsIds/slice";
import CarOnlySalonDataReducer from "./onlySalon/slice";
import motorCyclesDataReducer from "./motorcycles/slice";
import MopedSlugDataReducer from "./mopedSingleInfo/slice";
import activeMotorCycledDataReducer from "./motorcycles-active/slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    contact: contactReducer,
    blog: blogReducer,
    blog_slug: blogSlugReducer,
    cars: carsReducer,
    car_slug: carSlugReducer,
    carModels: carModelReducer,
    carNames: carNamesReducer,
    websiteMainData: websiteDataReducer,
    aboutUsData: aboutUsDataReducer,
    topCars: topCarReducer,
    favouriteCar: favouriteCarReducer,
    profile: profileDataReducer,
    likedCars: userLikedCarsData,
    changeUserData: ChangeUserProfileDataReducer,
    currencyExchange: currencyExchangeReducer,
    country: countryDataReducer,
    carFilter: carFilterCountryReducer,
    urgentSale: urgentSaleReducer,
    services: ecoMotorsServiceReducer,
    service_slug: serviceSlugReducer,
    car_type: carTypeDataReducer,
    car_type_slug: CarWithTypeSlugDataReducer,
    car_with_bank: creditWithBankDataReducer,
    car_with_bank_post: creditWithCardPostReducer,
    advertisement: advertisementReducer,
    write_review: writeReviewReducer,
    see_review: seeReviewReducer,
    sparePartsData: sparePartsInfoDataReducer,
    sparePartsCategory: sparePartsCategoryReducer,
    sparePartWithModelId: sparePartWithModelIdReducer,
    sparePartRequest: requestSparePartReducer,
    ourTeam: ourTeamReducer,
    availableCars: AvailableCarsSliceReducer,
    carCompareData: CarCompareDataReducer,
    carCompareSlug: carCompareSlugReducer,
    carAvailableSpareParts: AvailableSparePartsCarsReducer,
    salons: SalonDataReducer,
    onlySalon: CarOnlySalonDataReducer,
    motorCycles: motorCyclesDataReducer,
    mopedSingle: MopedSlugDataReducer,
    activeMotorCycles: activeMotorCycledDataReducer,
    mostViewedBlogs: mostViewedBlogsDataReducer,
  }
});
